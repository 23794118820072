import React from "react";

import star from "../../Images/star.png";
import { Link } from "react-router-dom";
import img_8 from "../../Images/8.jpg";
import img_11 from "../../Images/11.jpg";
import img_13 from "../../Images/13.jpg";
import img_14 from "../../Images/14.jpg";
import img_17 from "../../Images/17.jpg";
import img_18 from "../../Images/18.jpg";
import img_19 from "../../Images/19.jpg";
import img_20 from "../../Images/20.jpg";
import img_24 from "../../Images/24.jpg";
import Steps from '../../components/steps/steps.js'
import Hire from '../../components/hire/hire.js'
import { useState } from "react";


const Marketers = () => {
    const [open, setOpen] = useState(false);
  return (
    <div className="workers-container">
      <div className="workers-headings">
        <h5>Hire / marketers</h5>
        <h1>Hire Marketers</h1>
        <p>
          Hire freelance marketers from 24Task to take charge of your marketing
          initiatives and deliver tangible results.
        </p>
      </div>
      {/* ..............................FREELANCE ROW ONE ............................. */}
      <div className="pfreelancers">
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_8} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Influencer</h4>
                <h5>$12/hr</h5>
              </div>
              <div className="n-container">
                <h5>Marketer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>5/5</h5>
                  <h4>(647)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Email Marketing Consultants</h4>
            <h4>Influencer Marketing Experts</h4>
            <h4>Marketing Strategists</h4>
          </div>
          <div className="description">
            <h6>
              We are an agency dealing with over 100 popular influencers from
              many countries to help you promote your product, service or
              personal accounts.
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
          {open && <Hire closeModal={setOpen} />}
        </div>
        {/* .......................................COLUMN  ...................................... */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_11} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Admin Marketing</h4>
                <h5>$9/hr</h5>
              </div>
              <div className="n-container">
                <h5>Marketer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.87/5</h5>
                  <h4>(623)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Nielsen Marketing Cloud</h4>
            <h4>Market Knowledge</h4>
            <h4>Internet MArketing</h4>
          </div>
          <div className="description">
            <h6>
              Skills : Acquired proficiency in computer operating and written
              and verbal communication Extensive knowledge of the business
              procedures, routine company procedures...
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* ........................................column3............................................................................................................. */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_13} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Market-Help</h4>
                <h5>$9/hr</h5>
              </div>
              <div className="n-container">
                <h5>Marketer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.8/5</h5>
                  <h4>(581)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Internet MArketing</h4>
            <h4>Inbound marketing</h4>
            <h4>HubSpot Marketing</h4>
          </div>
          <div className="description">
            <h6>
              Skills Good teamwork skills Communication skills and networking
              ability Adaptability Strong attention to detail Good organisation
              and planning skills Creativity and writing skills
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* ..........................................row.................................................................................................. */}
      <div className="pfreelancers">
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_14} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Task-manager</h4>
                <h5>$6/hr</h5>
              </div>
              <div className="n-container">
                <h5>Marketer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.8/5</h5>
                  <h4>(539)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>HubSpot Marketing</h4>
            <h4>Marketing Automation</h4>
            <h4>Internet MArketing</h4>
          </div>
          <div className="description">
            <h6>
              Skills interpersonal skills good oral and written communication
              skills numeracy and analytical ability creativity and imagination
              influencing and negotiation skills teamwork organisational ability
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* .......................................COLUMN  ...................................... */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_17} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Marketing-tasks</h4>
                <h5>$4/hr</h5>
              </div>
              <div className="n-container">
                <h5>Marketer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.9/5</h5>
                  <h4>(496)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>HubSpot Marketing</h4>
            <h4>Nielsen Marketing Cloud</h4>
            <h4>SEMRush: Marketing SEO Tools</h4>
          </div>
          <div className="description">
            <h6>
              Education Rowan University ( 2011-2015) Bachelor of Business
              Administration ESSENTIAL Skills: Deliver specific, identifiable
              results Use data to make decisions
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* ........................................column3............................................................................................................. */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_18} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Maggie_T</h4>
                <h5>$8/hr</h5>
              </div>
              <div className="n-container">
                <h5>Marketer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.85/5</h5>
                  <h4>(464)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>QuickBooks Enterprise</h4>
            <h4>QuickBooks</h4>
            <h4>QuickBooks Online</h4>
            <h4>Sage Accounting</h4>
          </div>
          <div className="description">
            <h6>
              Professional Experience LCC Mall Tabaco ( 2011-2013) To work to
              Tax Return and VAT deadlines, as well as Financial Year End
              deadlines. present information in a professional manner
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* ..........................................row.................................................................................................. */}
      <div className="pfreelancers">
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_19} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Alekson</h4>
                <h5>$3/hr</h5>
              </div>
              <div className="n-container">
                <h5>Marketer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.71/5</h5>
                  <h4>(413)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Customer Support</h4>
            <h4>Creativity</h4>
            <h4>Facebook Marketers</h4>
            <h4>Twitter Marketing</h4>
          </div>
          <div className="description">
            <h6>
              A polite, friendly and extremely capable second-year Public
              Relations and Advertising Management student at RISEBA University.
              I hold fluent written communication and listening skills
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* .......................................COLUMN  ...................................... */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_20} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Paffet_R</h4>
                <h5>$14/hr</h5>
              </div>
              <div className="n-container">
                <h5>Marketer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>5/5</h5>
                  <h4>(387)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Digital Media</h4>
            <h4>Social Media Platforms</h4>
            <h4>Communication</h4>
            <h4>Documentation</h4>
          </div>
          <div className="description">
            <h6>
              As a PR and Marketing specialist, writing is one of the many
              things I love about my job. The creativity that sentences have to
              offer is what drives my passion.
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* ........................................column3............................................................................................................. */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_24} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>The Cj</h4>
                <h5>$3/hr</h5>
              </div>
              <div className="n-container">
                <h5>Marketer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>5/5</h5>
                  <h4>(342)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Creativity</h4>
            <h4>Problem-Solving</h4>
            <h4>Development</h4>
            <h4>Engineering</h4>
          </div>
          <div className="description">
            <h6>
              I can show you multiple way to understand that making or having
              money is just a tool have it your way with a powerful mindset
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Link>
        <button className="explore-btn">Explore more</button>
      </Link>
      <Steps />
    </div>
  );
};

export default Marketers;

import React from 'react'
import './steps.css'
import { Link } from 'react-router-dom'
import worry from '../../Images/worry.png'
import steps_img from '../../Images/steps-img.png'

function how() {
  return (
    <div className='steps'>
      <h1>How It Works</h1>
      <div className='steps-row'>
        <div className='steps-col'>
            <h2>1. Post Job / Hire</h2>
            <p>Register an Employer's Account and 
              Post your Job by filling the form provided.You can also browse freelancers by selecting the category where your job is based on and Hire.
              </p>
              <h2>2. Place your deposit</h2>
            <p>After posting your Job on the platform or hiring your preferred freelancer, you are required to deposit the amount with us. Don't worry, 24Tasks will hold the deposit until the job is completed.
              </p>
              <h2>3. Approve</h2>
            <p>Receive and review your project. If the project has met all your needs then you can approve it. The professional will be paid once you've approved it.
              </p>
              <Link to='/signup'><button className='getstarted-btn'>Get Started</button></Link>
        </div>
        <div className='steps-col'>
          <img src={steps_img} alt=''/>
        </div>
      </div>
      <hr></hr>
      <div className='quote-banner'>
        <div className='quote-col'>
          <h2><span>Not sure?</span> Post a job and get a free quote for your project from our Support or our team of freelancers.
          </h2>
          <Link to='/quote'><button className='getstarted-btn'>Get a Free Quote</button></Link>
        </div>
        <div className='banner-image'>
            <img src={worry} alt='man'/>
        </div>
      </div>

    </div>
  )
}

export default how
import React from 'react'

import star from '../../Images/star.png'
import { Link } from 'react-router-dom'
import img_38 from '../../Images/38.jpg'
import img_36 from '../../Images/36.jpg'
import img_42 from '../../Images/42.jpg'
import img_44 from '../../Images/44.jpg'
import img_32 from '../../Images/32.jpg'
import img_43 from '../../Images/43.jpg'
import img_45 from '../../Images/45.jpg'
import img_46 from '../../Images/46.jpg'
import img_47 from '../../Images/47.jpeg'
import Steps from '../../components/steps/steps.js'
import Hire from '../../components/hire/hire.js'
import { useState } from 'react'


const Developers = () => {
    const [open, setOpen]=useState(false)
  return (
    <div className='workers-container'>
        <div className='workers-headings'>
            <h5>Hire / developers</h5>
            <h1>Find Quality Freelancers and Agencies for Web Development</h1>
            <p>A strong Web Developers can make your business stand out from the crowd.</p>
        </div>
        {/* ..............................FREELANCE ROW ONE ............................. */}
        <div className='pfreelancers'> 
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_38} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Martin_006</h4>
                                <h5>$12/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Website Developer</h5>
                                <div className='star-row'>
                                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                                    <h5>4.85/5</h5>
                                    <h4>(624)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Web Design</h4>
                        <h4>Full Website Specialists</h4>
                        <h4>SEO</h4>
                        <h4>Database</h4>
                        <h4>Wordpress</h4>
                        <h4>Web Analytics</h4>
                    </div>
                    <div className='description'>
                        <h6>• Over 3 years of professional software and Web application development experience . familiar with coding JavaScript framework, JQuery, JSON, HTML, CSS, React and Wordpress.
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
                    {open && <Hire closeModal={setOpen}/>}    
            </div>
            {/* .......................................COLUMN  ...................................... */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_36} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Aisha413</h4>
                                <h5>$12/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Website Developer</h5>
                                <div className='star-row'>
                                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                                    <h5>4.82/5</h5>
                                    <h4>(602)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Web Developers</h4>
                        <h4>Web Designers</h4>
                        <h4>Full Website Creation Experts</h4>
                       
                    </div>
                    <div className='description'>
                        <h6>I am an experienced Virtual 
                            Assistant with 5+ years of work experience also worked as Web Designer , social media manager and finest content creator . 
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
            {/* ........................................column3............................................................................................................. */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_42} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Web_Tree</h4>
                                <h5>$15/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Web Developer</h5>
                                <div className='star-row'>
                                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                                    <h5>4.8/5</h5>
                                    <h4>(600)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>SEO</h4>
                        <h4>Web Analytics</h4>
                        <h4>Debugging</h4>
                        <h4>Software</h4>
                        <h4>Database Management</h4>
           
                    </div>
                    <div className='description'>
                        <h6>- wide experience in developing and testing web applications supported across all standard browser. 
                            - familiar with coding JavaScript framework, JQuery, JSON, HTML, CSS, and React
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
        </div>
        {/* ..........................................row.................................................................................................. */}
        <div className='pfreelancers'> 
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_44} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Djanviha</h4>
                                <h5>$14/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Web Developer</h5>
                                <div className='star-row'>
                                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                                    <h5>4.79/5</h5>
                                    <h4>(593)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>CMS Experience</h4>
                        <h4>jQuery Developers</h4>
                        <h4>Coding Skills</h4>
                        <h4>SQL</h4>
                        
                    </div>
                    <div className='description'>
                        <h6>• A result-oriented professional with over 3.5 years’ 
                            experience in web application development & enhancement, service delivery and client relationship management. • 
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
            {/* .......................................COLUMN  ...................................... */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_32} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Sophie.</h4>
                                <h5>$13/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Website Developer</h5>
                                <div className='star-row'>
                                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                                    <h5>4.76/5</h5>
                                    <h4>(591)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>SEO</h4>
                        <h4>SEO/SEM</h4>
                        <h4>Ahrefs: SEO Keyword Tool</h4>
                        <h4>HTML Developers</h4>
                        <h4>Web Design</h4>
                       
                    </div>
                    <div className='description'>
                        <h6>JavaScript, JQuery, HTML, HTML5, CSS, CSS3, web programming skills, 
                            E-commerce, teamwork, verbal communication, cross-browser compatibility, web user interface design (UI).
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
            {/* ........................................column3............................................................................................................. */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_43} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Jaker_</h4>
                                <h5>$14/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Website Developer</h5>
                                <div className='star-row'>
                                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                                    <h5>4.75/5</h5>
                                    <h4>(565)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Development</h4>
                        <h4>Develop Budgets</h4>
                        <h4>Chrome Developer Tools</h4>
                        <h4>Developing an argument</h4>
                       
                    </div>
                    <div className='description'>
                        <h6>Understand busy coding and Mark language Of all marked up 
                            languages hypertext markup language is HTML is the standard Make website for optimal user experience
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
        </div>
           {/* ..........................................row.................................................................................................. */}
           <div className='pfreelancers'> 
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_45} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Chamarda</h4>
                                <h5>$8/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Website Developer</h5>
                                <div className='star-row'>
                                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                                    <h5>4.73/5</h5>
                                    <h4>(546)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Web Analytics</h4>
                        <h4>AWeber</h4>
                        <h4>LinkedIn Website Demographics</h4>
                        <h4>Web Design</h4>
                        
                        
                    </div>
                    <div className='description'>
                        <h6>Seeking for a position of a software developer at your company to apply as 
                            a degree in computer science and two years experience in developing application requirement following system..
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
            {/* .......................................COLUMN  ...................................... */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_46} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Trafficker</h4>
                                <h5>$15/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Web Developer</h5>
                                <div className='star-row'>
                                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                                    <h5>4.62/5</h5>
                                    <h4>(537)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Research</h4>
                        <h4>Time Management</h4>
                        <h4>Data Entry</h4>
                        <h4>LiveChat</h4>
                        
                    </div>
                    <div className='description'>
                        <h6>Holding Bachelors and Masters in Law. My diverse experience inculcates of traffic law,
                             acquiring a rich experience through practice of law as an Attorney as various levels of courts 
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
            {/* ........................................column3............................................................................................................. */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_47} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Hardcrak</h4>
                                <h5>$17/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Website Developer</h5>
                                <div className='star-row'>
                                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                                    <h5>4.55/5</h5>
                                    <h4>(517)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Database Management</h4>
                        <h4>Microsoft Excel</h4>
                        <h4>HTML Developers</h4>
                        <h4>Web Design</h4>
                        <h4>jquery</h4>
                        
                    </div>
                    <div className='description'>
                        <h6>I have knowledge of php, html, css, jquery, data entry, database management etc.
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
        </div>
        <Link><button className='explore-btn'>Explore more</button></Link>
        <Steps/>
    </div>
  )
}

export default Developers
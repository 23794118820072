import React from "react";
import "./Home.css";

import Popular from "../Popular/Popular.js";
import Steps from "../steps/steps.js";
import Explore from "../explore/explore.js";
import cover from '../../Images/cover-2.png'
import check from '../../Images/check.png'
import Hero from '../hero/hero.js'


function Home() {
  return (
    <div className="home">
      <Hero />
      <Popular/>
      
      <div>
      </div>
      <div className="features">
        <div className="container">
          <div className="item">
            <h1>A whole world of freelance talent at your fingertips</h1>
            <div className="title">
              <img src={check} alt="" />
              The best for every budget
            </div>
            <p>
              Find high-quality services at every price point. You can chat to negotiate with a freelancer.
            </p>
            <div className="title">
              <img src={check}  alt="" />
              Quality work done quickly
            </div>
            <p>
              Find the right freelancer to begin working on your project within
              minutes.
            </p>
            <div className="title">
              <img src={check}  alt="" />
              Protected payments, every time
            </div>
            <p>
              Always know what you'll pay upfront. Your payment isn't released
              until you approve the work.
            </p>
            <div className="title">
              <img src={check}  alt="" />
              24/7 support
            </div>
            <p>
              Our Live Chat team is available 24/7 to respond to your questions and assist you navigate through our site.
            </p>
          </div>
          <div className="item2">
            <img src={cover} alt=""/>
          </div>
        </div>
      </div>
      <Steps/>
      <Explore/>
    
    </div>
  );
}

export default Home;

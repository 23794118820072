import React from "react";
import "./writers.css";

import star from "../../Images/star.png";
import { Link } from "react-router-dom";
import img_1 from "../../Images/1.jpg";
import img_2 from "../../Images/2.jpg";
import img_3 from "../../Images/3.jpg";
import img_4 from "../../Images/4.jpg";
import img_5 from "../../Images/5.jpg";
import img_6 from "../../Images/6.jpg";
import img_7 from "../../Images/7.jpg";
import img_9 from "../../Images/9.jpg";
import img_12 from "../../Images/12.jpg";
import Steps from '../../components/steps/steps.js'
import Hire from '../../components/hire/hire.js'
import { useState } from "react";


function Writers() {

    const [open, setOpen] = useState(false);

  return (
    <div className="workers-container">
      <div className="workers-headings">
        <h5>Hire / writers</h5>
        <h1>Hire Writers</h1>
        <p>
          Hire academic writers online for your erudite projects and get them
          done quickly at 24 Tasks.
        </p>
      </div>
      {/* ..............................FREELANCE ROW ONE ............................. */}
      <div className="pfreelancers">
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_1} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>ProfRonnie</h4>
                <h5>$9/hr</h5>
              </div>
              <div className="n-container">
                <h5>Writer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.8/5</h5>
                  <h4>(904)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Legal Writers</h4>
            <h4>Typist</h4>
            <h4>Creative Writers</h4>
            <h4>PowerPoint Experts</h4>
            <h4>Copywriters</h4>
            <h4>Medical Writers</h4>
          </div>
          <div className="description">
            <h6>
              i am highly experienced,goal oriented, have the ability to work
              for long and extended hours.I have superb skills in copy writing ,
              ghostwriting ,article writing, rewriting, Editting and general
              academic writing.
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
          {open && <Hire closeModal={setOpen} />}
        </div>
        {/* .......................................COLUMN  ...................................... */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_2} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Pro.Writer</h4>
                <h5>$12/hr</h5>
              </div>
              <div className="n-container">
                <h5>Writer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.61/5</h5>
                  <h4>(633)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Creativity</h4>
            <h4>Interpersonal Skills</h4>
            <h4>Leadership</h4>
            <h4>Article Writing</h4>
            <h4>Content Writing</h4>
            <h4>Report Writing</h4>
          </div>
          <div className="description">
            <h6>
              I am an expert freelancer and my working ground is based on Essay
              Writing; Research Writing; Case Study Analysis; Summary Writing;
              Dissertations; Thesis; Psychology; Literature;
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* ........................................column3............................................................................................................. */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_3} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>TutorE</h4>
                <h5>$10/hr</h5>
              </div>
              <div className="n-container">
                <h5>Writer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.58/5</h5>
                  <h4>(553)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Digital Media</h4>
            <h4>Communication</h4>
            <h4>Teamwork</h4>
            <h4>Web Analytics</h4>
            <h4>Problem-Solving</h4>
            <h4>Creativity</h4>
          </div>
          <div className="description">
            <h6>
              I have good organization and mobilization skills, able to work
              under pressure to meet deadline. I adhere to the details in my
              work and embrace teamwork spirit upholding high standards of
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* ..........................................row.................................................................................................. */}
      <div className="pfreelancers">
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_4} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Prof_frida</h4>
                <h5>$6/hr</h5>
              </div>
              <div className="n-container">
                <h5>Writer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.53/5</h5>
                  <h4>(486)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Research Assistants</h4>
            <h4>Internet Researchers</h4>
            <h4>tests</h4>
            <h4>Technical Writers</h4>
            <h4>Writing Skills</h4>
            
          </div>
          <div className="description">
            <h6>
              Are you stuck with your academic papers? Worry no more because I
              am here to help. I am Prof Frida, We are a team of proficient
              writers with expertise across all fields.
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* .......................................COLUMN  ...................................... */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_5} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Ayesha14</h4>
                <h5>$15/hr</h5>
              </div>
              <div className="n-container">
                <h5>Writer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.32/5</h5>
                  <h4>(614)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Facebook Marketers</h4>
            <h4>Blogging</h4>
            <h4>Social Media Platforms</h4>
            <h4>Instagram Marketing</h4>
            <h4>Teamwork</h4>
           
          </div>
          <div className="description">
            <h6>
              I am an experienced Virtual Assistant with 4+ years of work
              experience of working remotely with International level companies
              and a finest content creator , Doing bachelors in Information
              technology
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* ........................................column3............................................................................................................. */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_6} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Fredrick R</h4>
                <h5>$4/hr</h5>
              </div>
              <div className="n-container">
                <h5>Writer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.64/5</h5>
                  <h4>(516)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Creativity</h4>
            <h4>Problem-Solving</h4>
            <h4>Research</h4>
            <h4>Microsoft Word</h4>
            <h4>Writing Skills</h4>
            <h4>Communication</h4>
          </div>
          <div className="description">
            <h6>
              I have good organization and mobilization skills, able to work
              under pressure to meet deadline. I adhere to the details in my
              work and embrace teamwork spirit upholding high standards of
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* ..........................................row.................................................................................................. */}
      <div className="pfreelancers">
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_7} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Jack35</h4>
                <h5>$/hr</h5>
              </div>
              <div className="n-container">
                <h5>Writer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.73/5</h5>
                  <h4>(642)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Task Management</h4>
            <h4>Writing Skills</h4>
            <h4>Community Management</h4>
            <h4>Strategy Planning</h4>
            <h4>Typist</h4>
          </div>
          <div className="description">
            <h6>
              In the last 9 years as an SEO and Content Freelancer I have
              amassed vast experience which helps me understand the clients'
              instructions and guarantee them of high-quality
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* .......................................COLUMN  ...................................... */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_9} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Speedy-writer</h4>
                <h5>$20/hr</h5>
              </div>
              <div className="n-container">
                <h5>Writer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.6/5</h5>
                  <h4>(439)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Writing Skills</h4>
            <h4>Copywriting</h4>
            <h4>Writing and Communication</h4>
            <h4>Technical Writing</h4>
          </div>
          <div className="description">
            <h6>
              Professional Experience: Academic Write The College of Idaho
              2013-2016 Academic Writer University of Southern California – Los
              Angeles, CA 2016-2018 Academic Writer 24task company
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* ........................................column3............................................................................................................. */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_12} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Muhaad731</h4>
                <h5>$10/hr</h5>
              </div>
              <div className="n-container">
                <h5>Writer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.87/5</h5>
                  <h4>(415)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Facebook Marketers</h4>
            <h4>Blogging</h4>
            <h4>Instagram Marketing</h4>
            <h4>Teamwork</h4>
            <h4>Work Ethic</h4>
            <h4>Time Management</h4>
          </div>
          <div className="description">
            <h6>
              Hardwork is the key to success, that's my moto and my work is my
              motivation.
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Link>
        <button className="explore-btn">Explore more</button>
      </Link>
      <Steps />
    </div>
  );
};

export default Writers;

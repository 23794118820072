// src/App.js
import React from 'react';
import { Route, Routes} from 'react-router-dom';
import Signup from './components/Signup';
import Login from './components/Login';
import JobForm from './components/JobForm';
import JobList from './components/JobList';
import Home from "./components/Home/Home.js";
import './App.css';


import Writers from "./workers/writers/writers.js";
import Developers from "./workers/developers/developers.js";
import Designers from "./workers/designers/designers.js";
import Translators from "./workers/translators/translators.js";
import Marketers from "./workers/marketers/marketers.js";
import Steps from "./components/steps/steps.js";
import Contact from "./components/contact/contact.js";
import CustomerSupport from "./workers/customersupport/customersupport.js";
import Navbar from "./components/navbar/Navbar.js";
import Footer from "./components/footer/Footer.js";

const App = () => {
 
  return (
  
      <div className="App">
        <Navbar/>  
        <Routes>
        <Route path="/" element={<Home/>} />
          <Route path="/signup" element={<Signup/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/post-job" element={<JobForm/>} />
          <Route path="/dashboard" element={<JobList/>} />
          <Route path="/writers" element={<Writers />} />
          <Route path="/developers" element={<Developers />} />
          <Route path="/designers" element={<Designers />} />
          <Route path="/translators" element={<Translators />} />
          <Route path="/marketers" element={<Marketers />} />
          <Route path="/customer-support" element={<CustomerSupport />} />
          <Route path="/steps" element={<Steps />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<Home />} />
         </Routes>
         <Footer/>
      </div>
    
  );
};

export default App;

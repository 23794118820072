import React from 'react'
import './hire.css'
function Hire ({closeModal}){
    const [result, setResult] = React.useState("");
    const [success, setSuccess] = React.useState(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Submitting....");
    const formData = new FormData(event.target);

    formData.append("access_key", "cac0d1f2-0e7a-48bb-b938-7493f58500e0");

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      body: formData
    });

    const data = await response.json();

    if (data.success) {
      setResult("SUCCESS! Thankyou for choosing to work with me. I will contact you through email for more details or files if needed.");
      event.target.reset();
      setSuccess(true)
    } else {
      console.log("Error", data);
      setResult(data.message);
    }
  };
  return (
    <div className='modalBackground'>
        <div className='modalContainer '>
            <div className='titleCloseBtn'>
                <button onClick={()=>closeModal(false)}> x </button>
            </div>
            
            {result? <span className="result">{result}</span>:
            <div className='title'>
            <h1>Hire Me For Your Project</h1>
            </div>} 
           
            <div className="body">
            {success?
            <div className='navigationArea'>
                <div className='navMessage'>
                    <p>Your work will start immediately you make a deposit. If you wish to pay in full please Chat to negotiate the full amount based on your project needs.
                        If you experience any challenge you can LIVE CHAT customer Support for help.
                    </p>
                </div>
                <div className='navButtons'>
                    <button className='depositBtn'>Place Deposit</button>
                    <button className='negotiateBtn'>Chat | Negotiate</button>
                </div>
                
            </div>:
            <form onSubmit={onSubmit}>
                <label>Your Email</label>
                <input type='email' name='email' placeholder='Enter your email address' required/>
                <label>Project Title</label>
                <input type='text' name='title' placeholder='Project Title/ Project Category' required/>
                <label>Project Description</label>
                <textarea name='description' rows="6" placeholder='Give full description/instructions of your project' required/>
                <label>Deadline</label>
                <input type='text' name='deadline' placeholder='e.g 3 days or Date' required/>
                <label>Your Budget</label>
                <input type='text' name='budget' placeholder='From $10. We can negotiate about this...' required/>
                <button type="submit">Submit Project</button>
            </form> }
           
            </div>
            
        </div>
    </div>
  )
}

export default Hire
import React from 'react'
import './popular.css'
import mman from '../../Images/mman.png'
import Webprof from '../../Images/38.jpg'
import Tranprof from '../../Images/25.jpg'
import webdev from '../../Images/webdev.png'
import support from '../../Images/support.jpg'
import writing from '../../Images/writing.jpeg'
import star from '../../Images/star.png'
import { Link } from 'react-router-dom'
import Hire from '../hire/hire.js'
import { useState } from 'react'
function Popular() {
    const [open, setOpen]=useState(false)
  return (
    <div className='popular, container'>
        
        <div className='pservices'>
            <h1>Popular Services</h1>
            <div className='service-list'>
                <Link to='/developers'><button className='pbutton'>Web Developers</button></Link>
                <Link to='/writers'><button className='pbutton'>Writers</button></Link>
                <Link to='/customer-support'><button className='pbutton'>Customer Support</button></Link>
                <Link to='/marketers'><button className='pbutton'>Marketers</button></Link>
                <Link to='/designers'><button className='pbutton'>Designers</button></Link>
                <Link to='/translators'><button className='pbutton'>Translators</button></Link>
            </div>
        </div>
        <div className='pfreelancers'>
            <div className='freelance-card'>
                <img src={writing} alt='fimage' className='freelance-image'/>
                <div className='rating-row'>
                    <img src={star} alt=''/>
                    <img src={star} alt=''/>
                    <img src={star}alt=' '/>
                    <img src={star} alt=' '/>
                    <img src={star}alt=' '/>
                    <h5>4.95/5</h5>
                    <h4>(892)</h4>
                </div>
                <p>I will assist you in any kind of assignment, exams or research projects</p>
                <h3>From $20</h3>
            </div>
            <div className='freelance-card'>
                <img src={webdev} alt='fimage' className='freelance-image'/>
                <div className='rating-row'>
                    <img src={star}alt=' '/>
                    <img src={star}alt=' '/>
                    <img src={star}alt=' '/>
                    <img src={star}alt=' '/>
                    <img src={star}alt=' '/>
                    <h5>4.94/5</h5>
                    <h4>(693)</h4>
                </div>
                <p>I will develop fully responsive personal/business websites for you </p>
                <h3>From $90</h3>
            </div>
            <div className='freelance-card'>
                <img src={support} alt='fimage' className='freelance-image'/>
                <div className='rating-row'>
                    <img src={star}alt=' '/>
                    <img src={star}alt=' '/>
                    <img src={star}alt=' '/>
                    <img src={star}alt=' '/>
                    <img src={star}alt=' '/>
                    <h5>4.92/5</h5>
                    <h4>(904)</h4>
                </div>
                <p>I will be your live chat, receptionist or customer service rep</p>
                <h3>From $10</h3>
            </div>
        </div>


        <div className='popular-freelancers'>
            <div className='fheading'>
                <h1>Popular Freelancers</h1>
            </div>
            <div className='pfreelancers'>
                <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={mman} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>ProfRonnie</h4>
                                <h5>$9/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Writer</h5>
                                <div className='star-row'>
                                    <img src={star}alt=' '/>
                                    <img src={star}alt=' '/>
                                    <img src={star}alt=' '/>
                                    <img src={star}alt=' '/>
                                    <img src={star}alt=' '/>
                                    <h5>4.8/5</h5>
                                    <h4>(904)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Legal Writers</h4>
                        <h4>Typist</h4>
                        <h4>Creative Writers</h4>
                        <h4>PowerPoint Experts</h4>
                        <h4>Copywriters</h4>
                        <h4>Medical Writers</h4>
                    </div>
                    <div className='description'>
                        <h6>i am highly experienced,goal oriented, have the ability to work for 
                            long and extended hours.I have superb skills in copy writing ,
                            ghostwriting ,article writing, rewriting, Editting and general 
                            academic writing.
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn' onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    
                    </div>
                    {open && <Hire closeModal={setOpen}/>}
                
                </div>
                
                <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={Webprof} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Martin_006</h4>
                                <h5>$12/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Website Developer</h5>
                                <div className='star-row'>
                                    <img src={star}alt=' '/>
                                    <img src={star}alt=' '/>
                                    <img src={star}alt=' '/>
                                    <img src={star}alt=' '/>
                                    <img src={star}alt=' '/>
                                    <h5>4.85/5</h5>
                                    <h4>(624)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Web Design</h4>
                        <h4>Full Website Specialists</h4>
                        <h4>SEO</h4>
                        <h4>Database</h4>
                        <h4>Wordpress</h4>
                        <h4>Web Analytics</h4>
                    </div>
                    <div className='description'>
                        <h6>• Over 3 years of professional
                             software and Web application development experience . familiar with coding JavaScript framework, JQuery, JSON, HTML, CSS, React and Wordpress.
                            </h6>
                    </div>
                    <div className='hire-section'>
                        
                    
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
                </div>
                <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={Tranprof}alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>RubyTanslate</h4>
                                <h5>$13/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Translator</h5>
                                <div className='star-row'>
                                    <img src={star}alt=' '/>
                                    <img src={star}alt=' '/>
                                    <img src={star}alt=' '/>
                                    <img src={star}alt=' '/>
                                    <img src={star}alt=' '/>
                                    <h5>5/5</h5>
                                    <h4>(863)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Translation</h4>
                        <h4>Legal translation</h4>
                        <h4>English Grammar</h4>
                        <h4>Spanish translator</h4>
                        
                    </div>
                    <div className='description'>
                        <h6>Senior graphic design specialist with 6+ years of experience in graphic design, animation and creating creative logos. 
                            Creative graphic designer with innovative ideas and a unique...
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Popular
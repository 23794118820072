import React from "react";
import mman from "../../Images/mman.png";
import star from "../../Images/star.png";
import { Link } from "react-router-dom";
import img_48 from "../../Images/48.jpeg";
import img_49 from "../../Images/49.jpg";
import img_33 from "../../Images/33.jpg";
import img_50 from "../../Images/50.jpeg";
import img_51 from "../../Images/51.jpeg";
import img_40 from "../../Images/40.jpg";
import img_52 from "../../Images/52.jpeg";
import img_53 from "../../Images/53.jpeg";
import Steps from '../../components/steps/steps.js'
import Hire from '../../components/hire/hire.js'
import { useState } from "react";


const Designers = () => {
    const [open, setOpen] = useState(false);
  return (
    <div className="workers-container">
      <div className="workers-headings">
        <h5>Hire / designers</h5>
        <h1>Hire Designers</h1>
        <p>
          A strong Designers can make your business stand out from the crowd.
        </p>
      </div>
      {/* ..............................FREELANCE ROW ONE ............................. */}
      <div className="pfreelancers">
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={mman} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Designer</h4>
                <h5>$15/hr</h5>
              </div>
              <div className="n-container">
                <h5>Design</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.92/5</h5>
                  <h4>(663)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Design</h4>
            <h4>Graphic Design</h4>
            <h4>Web Design</h4>
            <h4>UI/UX design</h4>
            <h4>Print Design</h4>
          </div>
          <div className="description">
            <h6>
              Senior graphic design specialist with 6+ years of experience in
              graphic design, animation and creating creative logos. Creative
              graphic designer with innovative ideas
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
          {open && <Hire closeModal={setOpen} />}
        </div>
        {/* .......................................COLUMN  ...................................... */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_48} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Uniq-designs</h4>
                <h5>$9/hr</h5>
              </div>
              <div className="n-container">
                <h5>Design</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.46/5</h5>
                  <h4>(639)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>UI Designers</h4>
            <h4>UX Designers</h4>
            <h4>Adobe Photoshop Experts</h4>
            <h4>Web Designers</h4>
          </div>
          <div className="description">
            <h6>
              I am folexgraphics. I am a graphic design expert with a design
              sense. I am a professional Graphic designer with brand identity
              design knowledge. I've been working in this field since 2015.
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* ........................................column3............................................................................................................. */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_49} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>True_designs</h4>
                <h5>$15/hr</h5>
              </div>
              <div className="n-container">
                <h5>Web Developer</h5>
                <div className="star-row">
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.8/5</h5>
                  <h4>(523)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Photoshop</h4>
            <h4>Photography</h4>
            <h4>Adobe Photoshop</h4>
            <h4>Adobe Illustrator Experts</h4>
          </div>
          <div className="description">
            <h6>
              I have over 5 years of experience. As an Online Attorney, I can
              assist you with any legal aid/legal assistance job. Legal writing
              or other related activities such as proofreading,
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* ..........................................row.................................................................................................. */}
      <div className="pfreelancers">
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_33} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Johnson Ann</h4>
                <h5>$15/hr</h5>
              </div>
              <div className="n-container">
                <h5>Design</h5>
                <div className="star-row">
                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.29/5</h5>
                  <h4>(475)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Digital Advertising</h4>
            <h4>Campaign management</h4>
            <h4>Content creation</h4>
            <h4>SEO/SEM</h4>
          </div>
          <div className="description">
            <h6>
              I am an experienced and professional high quality Graphic
              Designer, article / blog writer, Web developer, android developer
              with vast skills to research and develop.
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* .......................................COLUMN  ...................................... */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_50} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Samco</h4>
                <h5>$16/hr</h5>
              </div>
              <div className="n-container">
                <h5>Design</h5>
                <div className="star-row">
                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.27/5</h5>
                  <h4>(452)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Instagram Marketing</h4>
            <h4>Time Management</h4>
            <h4>Transcription</h4>
            <h4>Adaptability</h4>
          </div>
          <div className="description">
            <h6>
              I am a hard worker willing to do any job. I get every job done
              quickly and I always do what is required extremely well. I am well
              versed in maths, English, history, the sciences, and arts.
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* ........................................column3............................................................................................................. */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_51} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Ricardo</h4>
                <h5>$14/hr</h5>
              </div>
              <div className="n-container">
                <h5>Design</h5>
                <div className="star-row">
                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.32/5</h5>
                  <h4>(433)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Data Entry</h4>
            <h4>Video Editing</h4>
            <h4>Photo Editing</h4>
            <h4>Virtual Assistant</h4>
            <h4>Data Mining</h4>
          </div>
          <div className="description">
            <h6>
              I am a passionate Video Editor, I can do simple task/edits like
              changing background, add objects and adding awesome
              effects/transition using filmora. Mostly I can do video intro,
              social media designs...
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* ..........................................row.................................................................................................. */}
      <div className="pfreelancers">
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_40} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Sabinzia</h4>
                <h5>$15/hr</h5>
              </div>
              <div className="n-container">
                <h5>Design</h5>
                <div className="star-row">
                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.54/5</h5>
                  <h4>(417)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Graphic Design</h4>
            <h4>Graphic Design</h4>
            <h4>Logo Design</h4>
            <h4>Brochure Design</h4>
            <h4>3D Design</h4>
          </div>
          <div className="description">
            <h6>
              Hi saad here. A professional graphic designer. I'm working on
              graphic designing from almost 4 years. I would love to work with
              you and provide you a speedy work.
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* .......................................COLUMN  ...................................... */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_52} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>The Beast</h4>
                <h5>$20/hr</h5>
              </div>
              <div className="n-container">
                <h5>Design</h5>
                <div className="star-row">
                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>5/5</h5>
                  <h4>(395)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Graphic Design</h4>
          </div>
          <div className="description">
            <h6>
              We bring your visions to life! Design Beast have been operating
              for 3 and a half years. We have worked on big projects and helped
              many digital companies with branding and logo making.
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
        {/* ........................................column3............................................................................................................. */}
        <div className="freelance-card">
          <div className="fheader">
            <div className="image-container">
              <img src={img_53} alt="prof" />
            </div>
            <div className="profile-container">
              <div className="name-container">
                <h4>Adward</h4>
                <h5>$15/hr</h5>
              </div>
              <div className="n-container">
                <h5>Design</h5>
                <div className="star-row">
                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <h5>4.65/5</h5>
                  <h4>(382)</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="extras">
            <h4>Facebook Marketers</h4>
            <h4>Typing Master</h4>
            <h4>Writing Skills</h4>
            <h4>Graphic Design</h4>
            <h4>Photoshop</h4>
          </div>
          <div className="description">
            <h6>
              I m a graphic designer, I will edit photos enhanced them and
              background change or removal of object efficiently and quickly.
            </h6>
          </div>
          <div className="hire-section">
            <Link to="https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4">
              <button className="chat-btn">Chat</button>
            </Link>
            <Link>
              <button
                className="hire-btn"
                onClick={() => {
                  setOpen(true);
                }}
              >
                Hire
              </button>
            </Link>
          </div>
        </div>
      </div>
      <Link>
        <button className="explore-btn">Explore more</button>
      </Link>
      <Steps />
    </div>
  );
};

export default Designers;

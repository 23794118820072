import React from 'react'
import './hero.css'

import { Link } from 'react-router-dom'

const hero = () => {
  return (
    <div className='hero'>
        <div className='hero-left'>
            <h1>Hire Freelancers & Get your project done.</h1>
            <div className='search-input'>
                <input placeholder='Who do you want to hire?' type='text'/>
                <button>Search</button>
            </div>
            <div className='hero-popular'>
                <span>Popular: </span>
                <Link to='/writers'><button>Writers</button></Link>
                <Link to='/developers'><button>Web Developers</button></Link>
                <Link to='/customer-support'><button>Customer Support</button></Link>
            </div>
        </div>
        <div className='hero-right'>
            
        </div>
    </div>
  )
}

export default hero
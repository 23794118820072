import React from 'react'

import star from '../../Images/star.png'
import { Link } from 'react-router-dom'
import img_25 from '../../Images/25.jpg'
import img_31 from '../../Images/31.jpg'
import img_29 from '../../Images/29.jpg'
import img_28 from '../../Images/28.jpg'
import img_37 from '../../Images/37.jpg'
import Steps from '../../components/steps/steps.js'
import Hire from '../../components/hire/hire.js'
import { useState } from 'react'


const Translators = () => {
    const [open, setOpen]=useState(false)
  return (
    <div className='workers-container'>
        <div className='workers-headings'>
            <h5>Hire / translators</h5>
            <h1>Hire Translators</h1>
            <p>Hire translators online and reach out to a vast audience network and create an emotional bond.</p>
        </div>
        {/* ..............................FREELANCE ROW ONE ............................. */}
        <div className='pfreelancers'> 
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_25} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Itranslate</h4>
                                <h5>$12/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Translator</h5>
                                <div className='star-row'>
                                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                                    <h5>5/5</h5>
                                    <h4>(865)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Translation</h4>
                        <h4>Spanish translator</h4>
                        <h4>Legal translation</h4>
                        <h4>English Grammar</h4>

                        
                    </div>
                    <div className='description'>
                        <h6>Gig description: We will professionally translate any text from any language to any language within the hours or days.  
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
                    {open && <Hire closeModal={setOpen}/>}
            </div>
            {/* .......................................COLUMN  ...................................... */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_31} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Agent462</h4>
                                <h5>$5/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Translator</h5>
                                <div className='star-row'>
                                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                                    <h5>5/5</h5>
                                    <h4>(617)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>3D Animators</h4>
                        <h4>Video Editors</h4>
                        <h4>Animators</h4>
                        <h4>Animated Explainers</h4>
                        <h4>Resume Writers</h4>
                       
                    </div>
                    <div className='description'>
                        <h6>Hello I'm Ike, a university student. I'm an editor, writer and can speak more than four languages.
                             It will be an honour to help you with your work. Don't worry about the money we will talk about it
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
            {/* ........................................column3............................................................................................................. */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_29} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Dickson</h4>
                                <h5>$5/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Translator</h5>
                                <div className='star-row'>
                                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                                    <h5>4.7/5</h5>
                                    <h4>(557)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Translation</h4>
                       
                       
           
                    </div>
                    <div className='description'>
                        <h6>Passionate multilingual translator with 2.5 years experience in Spanish-English 
                            translations and over 1 year with English-Spanish translations. Linguaphile from early childhood.
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
        </div>
        {/* ..........................................row.................................................................................................. */}
        <div className='pfreelancers'> 
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_28} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Chandini</h4>
                                <h5>$15/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Translator</h5>
                                <div className='star-row'>
                                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                                    <h5>4.9/5</h5>
                                    <h4>(521)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Voice Talent</h4>
                        <h4>Voice Over Specialists</h4>
                        <h4>Poster Designers</h4>

                        
                    </div>
                    <div className='description'>
                        <h6>Hi there, A warm welcome to you all. I hope all are doing great. This is chandini from india 
                            I am a translator and data entry operator having 2years experience iam working as a part time employee
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
            {/* .......................................COLUMN  ...................................... */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_37} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Zanchuk</h4>
                                <h5>$7/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Translator</h5>
                                <div className='star-row'>
                                <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                  <img src={star} alt="" />
                                    <h5>4.24/5</h5>
                                    <h4>(269)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Drawing</h4>
                        <h4>Bitcoin</h4>
                        <h4>Software Engineers</h4>
                        <h4>Network Engineering</h4>
                        
                    </div>
                    <div className='description'>
                        <h6>Hello, guys! I have been working as a Professional translator and also a Web Developer
                             for 5 years and I have a lot of experience with HTML, CSS and JavaScript!
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
        
        </div> 
        <Link><button className='explore-btn'>Explore more</button></Link>
        <Steps/>
    </div>
  )
}

export default Translators
import React from 'react'

import star from '../../Images/star.png'
import { Link } from 'react-router-dom'
import img_22 from '../../Images/22.jpg'
import img_21 from '../../Images/21.jpg'
import img_23 from '../../Images/23.jpg'
import img_16 from '../../Images/16.jpg'
import img_27 from '../../Images/27.jpg'
import img_15 from '../../Images/15.jpg'
import img_26 from '../../Images/26.jpg'
import img_30 from '../../Images/30.jpg'
import img_34 from '../../Images/34.jpg'
import Steps from '../../components/steps/steps.js'
import Hire from '../../components/hire/hire.js'
import { useState } from 'react'


function Customersupport(){
    const [open, setOpen]=useState(false)
  return (
    <div className='workers-container'>
        <div className='workers-headings'>
            <h5>Hire / customer-support</h5>
            <h1>Hire Customer Service Online</h1>
            <p>A strong Customer Supports can make your business stand out from the crowd.</p>
        </div>
        {/* ..............................FREELANCE ROW ONE ............................. */}
        <div className='pfreelancers'> 
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_22} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Danica_09</h4>
                                <h5>$15/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Customer Support</h5>
                                <div className='star-row'>
                                    <img src={star} alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <h5>4.95/5</h5>
                                    <h4>(645)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Blogging</h4>
                        <h4>Digital Media</h4>
                        <h4>Facebook Marketers</h4>
                        <h4>Work Ethic</h4>
                        <h4>Teamwork</h4>
                        <h4>Communication</h4>
                    </div>
                    <div className='description'>
                        <h6>Leadership Time management Ability to work in teams Ability to work well with people Ability to learn quickly
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
                    {open && <Hire closeModal={setOpen}/>}
            </div>
            {/* .......................................COLUMN  ...................................... */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_21} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Kaninvi</h4>
                                <h5>$40/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Customer Support</h5>
                                <div className='star-row'>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <h5>5/5</h5>
                                    <h4>(637)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Email</h4>
                        <h4>Client Relations</h4>
                        <h4>Creativity</h4>
                        <h4>Problem-Solving</h4>
                    
                    </div>
                    <div className='description'>
                        <h6>Translating and molding documentaries and drama series for dubbing in both formal and colloquial 
                            dialects. â¢ Documentaries, where each episode is between 7,000-9,000 words,
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
            {/* ........................................column3............................................................................................................. */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_23} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Randi_03</h4>
                                <h5>$10/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Customer Support</h5>
                                <div className='star-row'>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <h5>4.58/5</h5>
                                    <h4>(623)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>SEO</h4>
                        <h4>Social Media Platforms</h4>
                        <h4>Blogging</h4>

                    </div>
                    <div className='description'>
                        <h6>Dependent and self-motivated fresh graduate with solid experience of being trained
                             in a business process outsourcing company
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
        </div>
        {/* ..........................................row.................................................................................................. */}
        <div className='pfreelancers'> 
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_16} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Good Service</h4>
                                <h5>$10/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Customer Support</h5>
                                <div className='star-row'>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <h5>4.94/5</h5>
                                    <h4>(576)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Customer Support</h4>
                        <h4>Sell to Customer Needs</h4>
                        <h4>Customer service</h4>
                      
                    </div>
                    <div className='description'>
                        <h6>Main skills -Strong interpersonal communication -problem analysis and problem solving -organisational skills and customer service orientation -adaptability 
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
            {/* .......................................COLUMN  ...................................... */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_27} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Customer_care</h4>
                                <h5>$13/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Customer Support</h5>
                                <div className='star-row'>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <h5>4.57/5</h5>
                                    <h4>(528)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Writing Skills</h4>
                        <h4>Copywriting</h4>
                        <h4>Writing and Communication</h4>
                        <h4>Instagram Marketing</h4>
                        <h4>Technical Writing</h4>
                        
                    </div>
                    <div className='description'>
                        <h6>My passion for research has been my main thrust in freelance writing.
                             I read a great deal of materials before I chip away at any task given. Customer fulfillment is my principle point
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
            {/* ........................................column3............................................................................................................. */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_15} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Angela</h4>
                                <h5>$15/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Customer Support</h5>
                                <div className='star-row'>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <h5>4.64/5</h5>
                                    <h4>(496)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Blogging</h4>
                        <h4>Facebook Marketers</h4>
                        <h4>Communication</h4>
                        <h4>Social Media Platforms</h4>
                    </div>
                    <div className='description'>
                        <h6>Perform general clerical duties such as filing and scanning of documents Assigned in public 
                            assistance and information desk in answering clients queries Increase customer satisfaction.
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link ><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
        </div>
           {/* ..........................................row.................................................................................................. */}
           <div className='pfreelancers'> 
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_26} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Task-Mater</h4>
                                <h5>$11/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Customer Support</h5>
                                <div className='star-row'>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <h5>4.87/5</h5>
                                    <h4>(421)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Customer Support</h4>
                        <h4>Sell to Customer Needs</h4>
                        <h4>User / Customer Experience</h4>
                       
                        
                    </div>
                    <div className='description'>
                        <h6>Skills 1.fluency in the English language , with excellent communication skills 
                            both in verbal and oral communication 2.proficiency in numerous com yet application and software ,
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
            {/* .......................................COLUMN  ...................................... */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_30} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Vale-Best</h4>
                                <h5>$26/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Customer Support</h5>
                                <div className='star-row'>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <h5>4.94/5</h5>
                                    <h4>(398)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Customer Support</h4>
                        <h4>Sell to Customer Needs</h4>
                        <h4>User / Customer Experience</h4>
                       
                        
                    </div>
                    <div className='description'>
                        <h6>Customer service - deal with up to 100 customer daily -assisted customer in locating merchandise
                             and making purchasing decisions -increased monthly sales approximately $1000 utilising add...
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
            {/* ........................................column3............................................................................................................. */}
            <div className='freelance-card'>
                    <div className='fheader'>
                        <div className='image-container'>
                            <img src={img_34} alt='prof'/>
                        </div>
                        <div className='profile-container'>
                            <div className='name-container'>
                                <h4>Christian</h4>
                                <h5>$5/hr</h5>
                            </div>
                            <div className='n-container'>
                                <h5>Customer Support</h5>
                                <div className='star-row'>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <img src={star}alt=''/>
                                    <h5>4.87/5</h5>
                                    <h4>(383)</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='extras'>
                        <h4>Customer Support</h4>
                        <h4>Sell to Customer Needs</h4>
                        <h4>User / Customer Experience</h4>
                
                    </div>
                    <div className='description'>
                        <h6>Excellent written verbal communication leadership positive attitude and a fast learner
                            </h6>
                    </div>
                    <div className='hire-section'>
                    <Link to='https://tawk.to/chat/667fd86b9d7f358570d5453f/1i1hmape4'><button className='chat-btn'>Chat</button></Link>
                    <Link><button className='hire-btn'onClick={()=>{setOpen(true);}}>Hire</button></Link>
                    </div>
            </div>
        </div>
        <Link><button className='explore-btn'>Explore more</button></Link>
        <Steps/>
    </div>
  )
}

export default Customersupport
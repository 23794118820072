import React from 'react'
import './explore.css'
import { Link } from 'react-router-dom'

const explore = () => {
  return (
    <div className='explore-container'>
        <div>
        <div className='title-area'>
            <h3> Explore our Freelance Portal based on Category</h3>
        </div>
        <div className='freelance-cat'>
            
            <div className='cat-col'>
                <Link to='/'className='link'>Admin Support</Link>
                <Link to='/'className='link'>Engineers</Link>
                <Link to='/'className='link'>Mobile Developers</Link>
            </div>
            <div className='cat-col'>
                <Link to='/customer-support'className='link'>Customer Support</Link>
                <Link to='/'className='link'>Finance</Link>
                <Link to='/translators'className='link'>Translators</Link>
            </div>
            <div className='cat-col'>
                <Link to='/' className='link'>Data Scientist</Link>
                <Link to='/'className='link'>Lawyers</Link>
                <Link to='/developers'className='link'>Web Developers</Link>
            </div>
            <div className='cat-col'>
                <Link to='/designers'className='link'>Designers</Link>
                <Link to='/marketers'className='link'>Marketers</Link>
                <Link to='/writers'className='link'>Writers</Link>
            </div>
        </div>
        </div>
        <div>
        <div className='title-area'>
            <h3> Top Skills on Our Freelance Platform</h3>
        </div>
        <div className='freelance-cat'>
            
            <div className='cat-col'>
                <Link to='/writers'className='link'>Academic Writer</Link>
                <Link to='/designers'className='link'>Logo Designers</Link>
                
            </div>
            <div className='cat-col'>
                <Link to='/writers'className='link'>Article & Blog Writers</Link>
                <Link to='/'className='link'>PowerPoint Experts</Link>
                
            </div>
            <div className='cat-col'>
                <Link to='/customer-support' className='link'>Chat Support Agents</Link>
                <Link to='/'className='link'>Research Assistants</Link>
                
            </div>
            <div className='cat-col'>
                <Link to='/writers'className='link'>Essay Writers</Link>
                <Link to='/'className='link'>Virtual Assistants</Link> 
            </div>
        </div>
        </div>
    </div>
  )
}

export default explore